.ViewCarousel {
	width: 100%;
	height: calc(100% - 80px);
	position: absolute;
	top: 80px;
	left: 0;
	background-color: #dedede;
	overflow: hidden;
}

.ViewCarousel-carouselItem {
	height: 100%;
	width: 100%;
	position: absolute;
	display: flex;
	flex-direction: row;
	transition: 0.6s ease all;
}

.ViewCarousel-prevView {
	left:  -100%;
	opacity: 0.25;
}

.ViewCarousel-currentView {
	left: 0;
	right: 0;
	opacity: 1;
}

.ViewCarousel-nextView {
	right: -100%;
	opacity: 0.25;
}

.ViewCarousel-button {
	outline: 0;
	background: transparent;
	border: 0;
	width: 10%;
	transition: 0.3s ease all;
	cursor: pointer;
}

.ViewCarousel-button:hover {
	background-color: #3494BA;
}

.ViewCarousel-button:hover .chevron:before {
	border-color: white;
}

.ViewCarousel-button .chevron:before {
	border-width: .5em .5em 0 0;
	height: 3em;
	width: 3em;
	border-color: #3494BA;
}

.ViewCarousel-button:disabled {
	cursor: not-allowed;
}

.ViewCarousel-button:disabled .chevron:before {
	border-color: #CACACA;
}

.ViewCarousel-button:disabled:hover {
	background-color: #dedede;
}

.ViewCarousel-button:hover .chevron:before {
	border-color: #CACACA;
}

.ViewCarousel-viewContainer {
	width: 80%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.ViewCarousel-viewFooter {
	display: flex;
	flex-direction: column;
	transition: 0.3s ease all;
	height: 269px;
	overflow: hidden;
}

.ViewCarousel-viewFooter.is-closed {
	height: 19px;
}

.ViewCarousel-viewDescContainer {
	background:  white;
	padding: 0 10px 10px;
	height: 250px;
	overflow: auto;
  	box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.ViewCarousel-viewDescriptions {
	display: flex;
	flex-direction: row;
	background: white;
}

.ViewCarousel-problem,
.ViewCarousel-solution {
	flex-basis: 50%;
	padding: 0 5px;
}

.ViewCarousel-problem p,
.ViewCarousel-solution p {
	margin: 0;
}

.ViewCarousel-viewDescToggle {
	outline: 0;
	border: 0;
	padding: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	cursor: pointer;
	z-index: 3;
}

.ViewCarousel-viewDescToggle .chevron {
	margin-top: 5px;
	background: white;
	width: 3em;
	height: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1em 1em 0 0;
	transition: 0.3s ease all;
  	box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.ViewCarousel-viewDescToggle:hover .chevron {
	background: #3494BA;
}
.ViewCarousel-viewDescToggle:hover .chevron:before {
	border-color: white;
}

.ViewCarousel-viewDescToggle .chevron:before {
	transition: 0.3s ease;
	border-width: 0.15em 0.15em 0 0;
	border-color: ;
	width: 0.5em;
	height: 0.5em;
}

.ViewCarousel-viewDescToggle .chevron.top:before {
	top: 0.2em;
}

.ViewCarousel-viewDescToggle .chevron.bottom:before {
	top: -0.1em;
}

@media (max-width: 900px) {
	.ViewCarousel-viewDescToggle {
		display: none;
	}

	.ViewCarousel-button {
		position: fixed;
		width: 50%;
		bottom: 0;
		height: 40px;
		z-index: 1;
		background-color: #dedede;
  		box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
	}

	.ViewCarousel-buttonRight {
		margin-left: 50%;
	}

	.ViewCarousel-carouselItem {
		overflow-y: auto;
	}

	.ViewCarousel-button .chevron:before {
		top: 0;
		height: 1em;
		width: 1em;
	}

	.ViewCarousel-viewContainer {
		width: 100%;
		height: 100%;
		display: block;
	}

	.ViewCarousel-viewDescContainer,
	.ViewCarousel-viewFooter {
		height: fit-content;
		overflow: inherit;
		flex-shrink: 0;
	}

	.ViewCarousel-viewFooter {
		padding-bottom: 45px;
	}

	.ViewCarousel-viewDescriptions {
		flex-direction: column;
	}
}