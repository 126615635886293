.CarouselDockerPage {
	display: flex;
}

.CarouselDockerPage figure {
	margin: 5px;
	display: flex;
	align-items: center;
}

.CarouselDockerPage .material-symbols-outlined {
	margin-right: 5px;
}

.CarouselDockerPage-before,
.CarouselDockerPage-after {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	height: 100%;
}

.CarouselDockerPage-monolithApp {
	border: 2px solid #000;
	border-radius: 15px;
	text-align: center;
	padding: 15px;
	margin: 5px;
}

.CarouselDockerPage-service {
	border: 2px dashed #000;
	padding: 10px;
	border-radius: 10px;
}

.CarouselDockerPage-ecsService,
.CarouselDockerPage-user,
.CarouselDockerPage-frontEnd {
	border: 2px solid #000;
	padding: 10px;
	border-radius: 10px;
}

.CarouselDockerPage-ecsService {
	margin: 5px;
}

.CarouselDockerPage-ecsApp {
	border: 2px dashed #000;
	border-radius: 15px;
	text-align: center;
	padding: 15px;
	display: flex;
	flex-direction: row;
	margin: 5px;
}

.CarouselDockerPage .CarouselDockerPage-downArrow {
	width: 3px;
	height: 30px;
	background-color: #000;
	position: relative;
	margin-bottom: 7px;
}

.CarouselDockerPage-downArrow::before {
	content: "";
	width: 10px;
	height: 10px;
	border-bottom: 3px solid #000;
	border-right: 3px solid #000;
	transform: rotate(45deg);
	position: absolute;
	left: -5px;
	top: 17px;
}

.CarouselDockerPage-before h2,
.CarouselDockerPage-after h2 {
	align-self: stretch;
	text-align: center;
}

@media (max-width: 900px) {
	.CarouselDockerPage-before {
		padding-top: 5px;
	}


	.CarouselDockerPage-before h2 {
		margin-top: 0;
	}
}