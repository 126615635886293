.RouteNotFoundPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.RouteNotFoundPage .Error {
	background-color: red;
	color: white;
	padding: 10px;
	border-radius: 5px;
	font-size: 1.5em;
}