.CarouselMapView {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-bottom: 8px;
}

.CarouselMapView .Map {
	height: 100%;
	flex-basis: calc(50% - 8px);
	margin: 8px 4px 0 0;
}

.CarouselMapView .Map + .Map {
	height: 100%;
	flex-basis: calc(50% - 8px);
	margin: 8px 0 0 4px;
}

.CarouselMapView-loadButton {
	font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
	margin: 0 0 10px 10px;
	background-color: white;
	padding: 10px 20px;
	font-size: 18px;
	border-radius: 2px;
  	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  	cursor: pointer;
}

.CarouselMapView-legend {
	margin-top:  5px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.CarouselMapView-loadButton:hover {
	background-color: rgb(235, 235, 235);
}

.CarouselMapView-loadButton .LoadingSpinner {
	margin-right:  10px;
}

.CarouselMapView-loadButton figure {
	width: 20px;
	height: 20px;
	margin: 0;
}

.CarouselMapView-loadButton figure + figure {
	margin-left: 5px;
}

@media (max-width: 900px) {
	.CarouselMapView {
		flex-direction: column;
		padding-top: 5px;
	}

	.CarouselMapView .Map {
		height: 400px;
		margin: 0;
		margin: 0 10%;
	}

	.CarouselMapView .Map + .Map {
		height: 400px;
		margin: 10px 10% 0;
	}
}