.App-splashContainer {
  margin-top: 80px;
  height: 500px;
  overflow: hidden;
  transition: 0.5s ease all;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
}

.App-splashContainer.is-hidden {
  height: 0;
  box-shadow: none;
}

.Map {
  height:  500px;
  overflow: hidden;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
}

.Map-popupImage {
  object-fit: cover;
  width: 100%;
  height: 150px;
  color: #fff;
  border-radius: 10px 10px 0 0;
  margin-bottom: 5px;
}

.Map-popup {
  width: 618px;
}

.Map-popup h1 {
  text-align: center;
  font-size: 2em;
  margin: 0;
  background-color: #3494BA;
  color: white;
  padding: 5px;
}

.Map-popup h3 {
  text-align: center;
  font-size: 1.17em;    
  font-weight: bold;
  margin: 5px 0;
  background-color: #D4EAF3;
  padding: 5px;
}

.App-header {
  display: flex;
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.App-content {
  padding: 10px 20% 120px 20%;
}

.App-button { 
  background-color: #3494BA;
  border: 0;
  color: white;
  cursor: pointer;
  transition: 0.3s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-button:hover {
  background-color: #60b2d2; /* 25% lightening to #3494BA */
}

.App-button:disabled {
  background-color: #cacaca;
}

a {
  color: #87B14E;
}

/* Page Title */
h1 {
  text-align: center;
  color: #3494BA;
  font-size: 64px;
  margin: 0;
  font-weight: lighter;
}

/* Section Headers */
h2 {
  background-color: #3494BA;
  color: white;
  padding: 5px 10px;
  margin: 10px 0;
}

/* Element Headers */
h3 {
  color: #3494BA;
  font-size: 24px;
  margin: 0;
  font-weight: normal;
}

.App-menu {
  list-style: none;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0;
  height: 100%;
}

.App-logo {
  flex-basis: 50%;
  color: #3494BA;
  font-size: 24px;
  margin: 0;
  font-weight: normal;
  padding-left: 10px;
  cursor: pointer;
}

.App-logo:hover {
  color: #60b2d2; /* 25% lightening to #3494BA */
}

.App-menuTray {
  flex-basis: 50%;
  display: flex;
  justify-content: right;
  padding-right: 10px;
}

.App-menuToggle {
  width: 40px;
  height: 40px;
  right: 0;
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  top: 0.25em;
  height: 1em;
  position: relative;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 1em;
}

.chevron.bottom:before {
  top: -0.25em;
  transform: rotate(135deg);
}

.chevron.left:before {
  top: -0.25em;
  transform: rotate(-135deg);
}

.chevron.right:before {
  top: -0.25em;
  transform: rotate(45deg);
}

.App-menu li {
  padding: 28px 25px;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s ease all;
  color: #3494BA;
}

.App-menu a {
  outline: none;
  text-decoration: none;
}

.App-menu li:hover,
.App-menu li.is-selected {
  background-color: #3494BA;
  color: white;
}

.App-footer {
  background-color: #D4EAF3;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
}

.App-menuLogoContainer {
  flex-basis: 50%;
}

.Logo-container {
  height: 80px;
  width: 250px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.Logo-container:hover {
  background-color: #3494BA;
}

.Logo-artContainer {
  position: relative;
  top: -4px;
  height: 80px;
  width: 40px;
}

.Logo-k {
  position: absolute;
  height: 2.5em;
  width: 2em;
  overflow: hidden;
  top: 1.5em;
}

.Logo-c {
  position: absolute;
  height: 0;
  width: 0;
}

.Logo-k:before {
  position: absolute;
  border-color: #87B14E;
  border-style: solid;
  border-width: 0 0.4em 0 0; 
  content: '';
  display: inline-block;
  height: 2.5em;
  top: 0em;
  left: 0;
}

.Logo-k:after {
  position: absolute;
  border-color: #87B14E;
  border-style: solid;
  border-width: 0.4em 0.4em 0 0;
  content: '';
  display: inline-block;
  height: 2em;
  width: 2em;
  position: relative;
  transform: rotate(-135deg);
  vertical-align: top;
  top: 0em;
  left: .5em;
}

.Logo-c:before {
  position: absolute;
  border-color: #3494BA;
  border-style: solid;
  border-width: 0.4em 0.4em 0 0;
  content: '';
  display: inline-block;
  height: 1.35em;
  width: 1.35em;
  position: relative;
  transform: rotate(-135deg);
  vertical-align: top;
  top: 1.85em;
  left: 1.37em;
}

.Logo-container:hover .Logo-c:before {
  border-color: white;
}

.Logo-fullName {
  margin-left: 10px;
  border: 2px solid #3494BA;
  color: #3494BA;
  font-size: 24px;
  text-align: center;
  padding: 3px 8px;
  font-variant: small-caps;
}

.Logo-container:hover .Logo-fullName {
  color: white;
  border-color: white;
}

.PhotoGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PhotoGallery-imageCell {
  width:  360px;
  height: 270px;
  margin: 3px;
  border-radius: 2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.PhotoGallery-image {
  position: absolute;
  width:  360px;
  height: 270px;
  transition: all 0.3s ease;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 0;
}

.PhotoGallery-imageCell.is-selected .PhotoGallery-image {
  width: 726px;
  height: 546px;
  z-index: 2;
}

.PhotoGallery-imageCell:nth-child(3n) .PhotoGallery-image {
  right: 0;
}

.PhotoGallery-image:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.PhotoGallery-imageCell.is-selected .PhotoGallery-image:hover {
  box-shadow: none;
  z-index: 1;
}

.PhotoGallery-closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  color: white;
  transition: all 0.3s ease;
  background-color: black;
  opacity: 0.4;
}

.PhotoGallery-closeIcon:hover {
  opacity: 1;
}

.ContactForm {
  text-align: center;
}

.ContactForm-label {
  text-align: left;
  display: flex;
  flex-direction: column;
  border: 1px solid #cacaca;
  padding: 3px 5px;
  margin-top: 10px;
  color: #aaaaaa;
  font-variant: small-caps;
  transition: all 0.3s ease;
}

.ContactForm-label:focus-within {
  border: 1px solid #3494BA;
  color: #3494BA;
}

.ContactForm-input,
.ContactForm-label input:focus {
  border: 0;
  outline: none;
}

.ContactForm-input {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: lighter;
  color: #696969;
  margin: 0;
  padding: 3px 0 0;
}

.ContactForm-textArea {
  height: 10em;
}

.ContactForm-submit {
  font-variant: small-caps;
  padding: 10px;
  font-size: 2em;
  margin-top: 10px;
}

.Map-headshot {
  left: 40px;
  top:  25px;
  border:  2px solid white;
  position: absolute;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
}

.About-fullStory {
  display: flex;
}

.About-fullStory h3 {
  margin-top: 10px;
  text-align: center;
}

.About-fullStory img {
  width: 80%;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.About-fullStory section {
  flex-basis: 33%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PhotoGallery-description {
  text-align: center;
}

.ErrorBoundary {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1846px) {
  .PhotoGallery-imageCell:nth-child(2n) .PhotoGallery-image {
    right: 0;
  }
}

@media (max-width: 1240px) {
  .App-content {
    padding: 10px 10% 120px 10%;
  }
}

@media (max-width: 931px) {
  .PhotoGallery {
    flex-flow: column;
    align-items: center;
  }

  .PhotoGallery-imageCell,
  .PhotoGallery-image {
    width: 100%;
    max-width: 720px;
    aspect-ratio: 4/3;
    height: auto;
  }
}

@media (max-width: 800px) {
  .Map-headshot {
    top: 60px;
    width: 120px;
    height: 120px;
  }

  .App-menu li {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }

  .About-fullStory {
    flex-direction: column;
  }

  .App-menu {
    padding: 0;
  }

  .Map-popup {
    width: 425px;
  }
}

@media (max-width: 600px) {
  .Map-popup {
    width: 100%;
  }

  .Logo-fullName {
    display: none;
  }

  .Logo-container {
    width: 45px;
  }

  .App-content {
    padding: 5%;
  }

  .App-menuTray,
  .App-menuLogoContainer {
    flex-basis: auto;
  }

  .Map-headshot {
    top: 50px;
    left: calc(50% - 70px);
  }

  .App-menu li {
    font-size: 14px;
  }

  .About-fullStory img {
    width: 100%;
  }

  h1 {
    font-size: 36px;
  }

  h2, h3 {
    font-size: 20px;
  }
}