.ViewCarouselView {
	width: 100%;
	height: calc(100% - 19px);
	transition: all 0.3s ease;
}

.ViewCarouselView.with-footer {
	height: calc(100% - 269px);
}

.ViewCarouselView-titleSlide {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ViewCarouselView-titleSlide p {
	width: 50%;
}

.ViewCarouselView-mobileFriendly {
	display: none;
}

@media (max-width: 900px) {
	.ViewCarouselView,
	.ViewCarouselView.with-footer {
		display: block;
		width: 100%;
		height: fit-content;
		flex-shrink: 0;
	}

	.ViewCarouselView.ViewCarouselView-titleSlide {
		display: flex;
		padding: 30% 0;
	}

	.ViewCarouselView-titleSlide p {
		width: 80%;
	}
}