.CarouselUploaderPage {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 10px;
}

.CarouselUploaderPage .Card {
	height: 100%;
	align-self: stretch;
	background-color: #fff;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-radius: 2px;
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.CarouselUploaderPage-uploader,
.CarouselUploaderPage-gridContainer,
.CarouselUploaderPage-graph,
.CarouselUploaderPage-map {	
	height: 100%;
	width: calc(25% - 9px);
	margin-right: 12px;
	/*flex-grow: 0;*/
	padding: 5px;
	position: relative;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	max-width: 50%;
}

.CarouselUploaderPage-map {
	margin-right: 0;
}

.CarouselUploaderPage-uploader {
	font-size: 12px;
}

.CarouselUploaderPage-uploader .Card {
	overflow: auto;
}

.CarouselUploaderPage-uploader p,
.CarouselUploaderPage-uploader ul {
	margin: 0.5em 0;
}

.CarouselUploaderPage-uploader .DragAndDropUploader {
	margin-top: 20px;
}

.CarouselUploaderPage h2 {
	align-self: stretch;
	text-align: center;
}

.CarouselUploaderPage-grid {
	height: calc(100% - 48px);
	width: 100%;
	overflow: auto;
}

.CarouselUploaderPage-grid td,
.CarouselUploaderPage-grid th {
	border-right:  1px solid #cacaca;
	border-bottom:  1px solid #cacaca;
	padding: 5px;
	white-space: nowrap;
}

.CarouselUploaderPage-grid tr {
	transition: 0.3s ease all;
}

.CarouselUploaderPage-grid tr:hover {
	background-color: #ededed;
}

.CarouselUploaderPage-grid table {
	border-collapse: collapse;
	border:  1px solid #cacaca;
}

.CarouselUploaderPage-graph .Graph {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.CarouselUploaderPage-map .Map {
	width: 100%;
	height: 100%;
}


@media (max-width: 900px) {
	.CarouselUploaderPage-uploader,
	.CarouselUploaderPage-gridContainer,
	.CarouselUploaderPage-graph,
	.CarouselUploaderPage-map {
		height: fit-content;
		width: calc(100% - 10px);
		max-width: calc(100% - 10px);
		padding: 0;
		margin: 5px;
	}

	.CarouselUploaderPage-map .Map,
	.CarouselUploaderPage-grid {
		height: 400px;
	}
}