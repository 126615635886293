.DragAndDropUploader {
	display: flex;
	flex-direction: column;
	text-align: center;
	position: relative;
	width: 100%;
}

.DragAndDropUploader-fileInput {
	display: none;
}

.DragAndDropUploader-button {
	border: 5px dashed #acacac;
	padding: 10px 20px;
	transition: 0.3s ease all;
	color: #acacac;
	cursor: pointer;
	font-variant: small-caps;
	font-size: 20px;
	flex-grow: 1;
}

.DragAndDropUploader-button:hover {
	border-color: #3494BA;
	color: #3494BA;
}

.DragAndDropUploader-button.is-dragging {
	border-color: #3494BA;
	color: #3494BA;
}

.DragAndDropUploader-invalidText {
	display: block;
	margin-top: 10px;
	background-color: #fff;
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
	transition: 0.3s ease all;
	font-style: italic;
	padding: 5px;
	opacity: 0;
	border: 1px solid #cdcdcd;
	border-radius: 2px;
}

.DragAndDropUploader-invalidText::before {
	content: "";
	position: absolute;
	left: calc(50% - 5px);
	top: 62px;
	width: 10px;
	height: 10px;
	background-color: #fff;
	transform: rotate(45deg);
	z-index: 0;
	border-left: 1px solid #cdcdcd;
	border-top: 1px solid #cdcdcd;
}

.DragAndDropUploader-invalidText.is-invalid-upload {
	opacity: 1;
}