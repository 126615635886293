.CarouselGraphView {
	display: flex;
	flex-flow: row;
	width: 100%;
}

.CarouselGraphView .Graph {
	overflow: hidden;
}

.CarouselGraphView-column {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
}

.CarouselGraphView-column > section {
	display: flex;
	flex-direction: row;
	height: 50%;
}

.CarouselGraphView-column .Graph {
	margin: 4px;
}

.CarouselGraphView-column > .Graph {
	height: 50%;
	flex-grow: 0;
}

.CarouselGraphView-column > section > .Graph {
	width: 100%;
}

.CarouselGraphView-smallCharts .Graph {
	flex-basis: 50%;
}

@media (max-width: 900px) {
	.CarouselGraphView {
		flex-direction: column;
	}

	.CarouselGraphView-column {
		flex-basis: 100%;
	}
}