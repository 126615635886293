.LoadingSpinner {
  height: 15px;
  width: 15px;
  color: #3494BA;
  position: relative;
  display: inline-block;
  border: 3px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg); 
  } 
}